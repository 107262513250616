import React, { Component } from "react";
import calcularPx from "../general/calcularPx.js";
import Mapa from "../general/mapa/mapa.jsx";
import Select from "react-dropdown-select";

class PuntosDeVenta extends Component {
  state = {
    provincias: [],
    localidades: [],
    puntos: [],
    seleccionado: { id: -1, lat: -34.613292, lng: -58.388752 },
    localidad: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let seleccionado = { id: -1, lat: -34.613292, lng: -58.388752 };
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          seleccionado = {
            id: -1,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.setState(
            {
              seleccionado: seleccionado,
            },
            () => {
              this.setPuntos(seleccionado);
            }
          );
        },
        (error) => {
          this.setPuntos(seleccionado);
        }
      );
    } else {
      this.setPuntos(seleccionado);
    }
    fetch("/api/mapa/provincias")
      .then((res) => res.json())
      .then((data) => {
        this.setState({ provincias: data });
      });
  }

  handleChangeProvincia(value) {
    this.selectLocMethods.clearAll();
    document.getElementById("Localidad").value = "";
    if (value) {
      document.getElementById("Provincia").value = value.nombre;
      fetch("/api/mapa/localidades/?idProv=" + value.id)
        .then((res) => res.json())
        .then((data) => {
          this.setState({ localidades: data, localidad: null });
        });
    } else {
      this.setState({ localidades: [], localidad: null });
    }
  }

  handleChangeLocalidad(value) {
    if (value) {
      document.getElementById("Localidad").value = value.nombre;
      if (value.id !== this.state.seleccionado?.id) {
        this.setState({
          localidad: value
        });
      }
    }
  }

  buscar() {
    let seleccionado = {
      id: this.state.localidad.id,
      lat: Number(this.state.localidad.centerLat),
      lng: Number(this.state.localidad.centerLng),
    };
    this.setPuntos(seleccionado);
  }

  setPuntos(seleccionado) {
    fetch(
      "/api/mapa/puntosDeVenta/?idLoc=" +
        seleccionado.id +
        "&lat=" +
        seleccionado.lat +
        "&lng=" +
        seleccionado.lng
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({ puntos: data, seleccionado: seleccionado });
      });
  }

  customSearch({ props, state, methods }) {
    var normalize = (function () {
      var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
        to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
        mapping = {};

      for (var i = 0, j = from.length; i < j; i++)
        mapping[from.charAt(i)] = to.charAt(i);

      return function (str) {
        var ret = [];
        for (var i = 0, j = str.length; i < j; i++) {
          var c = str.charAt(i);
          if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
          else ret.push(c);
        }
        return ret.join("");
      };
    })();
    const regexp = new RegExp(
      normalize(methods.safeString(state.search).replace(/\s+/g, " ").trim()),
      "i"
    );
    return methods
      .sortBy()
      .filter((item) => regexp.test(normalize(item[props.searchBy])));
  }

  customContentRenderer({ props, methods }) {
    return (
      <React.Fragment>
        <div
          className="select"
          style={{ width: "100%", height: "100%"}}
        >
          <input
            type="text"
            id={props.placeholder}
            onChange={(event) => methods.setSearch(event)}
            placeholder={props.placeholder}
            style={{
              border: "none",
              borderColor: "transparent",
              outline: "none",
              caretColor: "transparent",
              width: "100%",
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const mobile = window.innerWidth < 1280;
    return (
      <React.Fragment>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: 300,
          }}
        >
          <img
            src={require("../../res/banners/donde_comprar_" + (mobile ? "mobile":"desktop" ) + ".jpg")}
            style={{
              position: "relative",
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
              height: 300,
              zIndex: -1,
            }}
          />
          <img
            src={require("../../res/banners/donde_comprar_titulo.png")}
            style={{
              position: "absolute",
              objectFit: "cover",
              objectPosition: "left",
              width: "45%",
              maxWidth: 466,
              height: "auto",
              top: 113,
              left: "10%",
              zIndex: 0,
            }}
          />
        </div>

        <div style={{ width: "100%", height: 60 }} />
        <div 
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div style={{display: "flex", width: "100%", flexWrap: "wrap", gap: 50, alignContent: "flex-end", alignItems: "flex-end"}}>
            <div className="select_titulo" style={{flexGrow: 1}}>
              Provincia
              <Select
                className="select_drop"
                style={{
                  marginTop: 8,
                  width: "100%"
                }}
                color="#9d1c21"
                placeholder="Provincia"
                searchable={true}
                separator={true}
                noDataLabel={"No hay resultados."}
                handleKeyDownFn={({ methods, state }) => {
                  let input = document.getElementById("Provincia");
                  input.focus();
                  if (state.values[0]) {
                    methods.clearAll();
                    input.value = "";
                  }
                }}
                separatorRenderer={({ methods, state }) => {
                  this.selectProvMethods = methods;
                }}
                dropdownGap={0}
                labelField={"nombre"}
                searchBy={"nombre"}
                valueField={"id"}
                options={this.state.provincias}
                onChange={(values) => this.handleChangeProvincia(values[0])}
                contentRenderer={this.customContentRenderer}
                searchFn={this.customSearch}
                />
            </div>

            <div className="select_titulo" style={{flexGrow: 1}}>
              Localidad
            <Select
              className="select_drop"
              style={{
                marginTop: 8,
                width: "100%",
              }}
              color="#9d1c21"
              placeholder="Localidad"
              searchable={true}
              separator={true}
              noDataLabel={"No hay resultados."}
              handleKeyDownFn={({ methods, state }) => {
                let input = document.getElementById("Localidad");
                input.focus();
                if (state.values[0]) {
                  methods.clearAll();
                  input.value = "";
                }
              }}
              separatorRenderer={({ methods }) => {
                this.selectLocMethods = methods;
              }}
              dropdownGap={0}
              labelField={"nombre"}
              searchBy={"nombre"}
              valueField={"id"}
              options={this.state.localidades}
              onChange={(values) => this.handleChangeLocalidad(values[0])}
              contentRenderer={this.customContentRenderer}
              searchFn={this.customSearch}
            />
            </div>

            <div style={{flexGrow: 1}}>
              <button disabled={!this.state.localidad} onClick={() => this.buscar()} className="select_boton" style={{width: "100%"}}>Buscar</button>
            </div>
          </div>
          <div style={{ height: 50, width: "100%" }} />
          <div style={{ display: mobile ? "block" : "flex" }}>
            <div
              id="mapaConLista"
              style={{
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Mapa
                verMapa={true}
                verLista={true}
                puntos={this.state.puntos}
                seleccionado={this.state.seleccionado}
                mobile={mobile}
              />
            </div>
          </div>
        </div>
        <div style={{width: "100%", height: "7vw"}}/>
      </React.Fragment>
    );
  }
}

export default PuntosDeVenta;
