import React, { Component } from "react";
import calcularPx from "../general/calcularPx";
import Select from "react-dropdown-select";
import { ReactComponent as IcoMas } from "../../res/iconos/masArchivo.svg";
import Gracias from "../general/gracias";

class Posventa extends Component {
  state = {
    email: "",
    emailValido: false,
    nombre: "",
    nombreValido: false,
    apellido: "",
    apellidoValido: false,
    dni: "",
    dniValido: false,
    celular: "",
    celularValido: false,
    calle: "",
    calleValido: false,
    altura: "",
    alturaValido: false,
    piso: "",
    pisoValido: true,
    dpto: "",
    dptoValido: true,
    idProvincia: -1,
    provincias: [],
    provincia: "",
    provinciaValido: false,
    localidades: [],
    idLocalidad: -1,
    localidad: "",
    localidadValido: false,
    codpostal: "",
    codpostalValido: false,
    nombreInstalador: "",
    nombreInstaladorValido: false,
    apellidoInstalador: "",
    apellidoInstaladorValido: false,
    matriculaInstalador: "",
    matriculaInstaladorValido: false,
    producto: "",
    productoValido: false,
    serie: "",
    serieValido: false,
    fecha_compra: "",
    fecha_compraValido: false,
    comercio: "",
    comercioValido: false,
    archivoFactura: null,
    nombreFactura: "Elegir archivo",
    archivoImagen1: null,
    nombreImagen1: "Elegir imagen",
    archivoImagen2: null,
    nombreImagen2: "Elegir imagen",
    falla: "",
    fallaValido: false,
    error: null,
    enviando: false,
    nroReclamo: -1,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    fetch("/api/mapa/provincias")
      .then((res) => res.json())
      .then((data) => {
        this.setState({ provincias: data });
      });
  }

  fileToDataUrl = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

  fileToBase64 = (blob) => this.fileToDataUrl(blob).then((text) => text);

  async enviar() {
    this.setState({ error: null, enviando: true });
    let archivoFactura = await this.fileToBase64(this.state.archivoFactura);
    let archivoImagen1 = await this.fileToBase64(this.state.archivoImagen1);
    let archivoImagen2 = await this.fileToBase64(this.state.archivoImagen2);
    const res = await fetch("/api/posventa/cargarReclamo", {
      method: "post",
      body: JSON.stringify({
        email: this.state.email,
        nombre: this.state.nombre,
        apellido: this.state.apellido,
        dni: this.state.dni,
        celular: this.state.celular,
        calle: this.state.calle,
        altura: this.state.altura,
        piso: this.state.piso,
        dpto: this.state.dpto,
        idProvincia: this.state.idProvincia,
        provincia: this.state.provincia,
        idLocalidad: this.state.idLocalidad,
        localidad: this.state.localidad,
        codpostal: this.state.codpostal,
        nombreInstalador: this.state.nombreInstalador,
        apellidoInstalador: this.state.apellidoInstalador,
        matriculaInstalador: this.state.matriculaInstalador,
        producto: this.state.producto,
        serie: this.state.serie,
        fecha_compra: this.state.fecha_compra,
        comercio: this.state.comercio,
        archivoFactura: archivoFactura,
        facturaOriginal: this.state.nombreFactura,
        archivoImagen1: archivoImagen1,
        imagen1Original: this.state.nombreImagen1,
        archivoImagen2: archivoImagen2,
        imagen2Original:
          archivoImagen2 != null ? this.state.nombreImagen2 : "null",
        falla: this.state.falla,
      }),
      headers: { "Content-Type": "application/json" },
    });
    const data = await res.json();
    if (data.nroReclamo !== -1) {
      this.setState({ nroReclamo: data.nroReclamo, enviando: false });
    } else {
      this.setState({ enviando: false });
    }
  }

  validarMail(email) {
    const emailRegex =
      /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    if (!email) return false;

    if (email.length > 254) return false;

    let valid = emailRegex.test(email);
    if (!valid) return false;

    var parts = email.split("@");
    if (parts[0].length > 64) return false;

    var domainParts = parts[1].split(".");
    if (
      domainParts.some(function (part) {
        return part.length > 63;
      })
    )
      return false;

    return true;
  }

  customSearch({ props, state, methods }) {
    var normalize = (function () {
      var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
        to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
        mapping = {};

      for (var i = 0, j = from.length; i < j; i++)
        mapping[from.charAt(i)] = to.charAt(i);

      return function (str) {
        var ret = [];
        for (var i = 0, j = str.length; i < j; i++) {
          var c = str.charAt(i);
          if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
          else ret.push(c);
        }
        return ret.join("");
      };
    })();
    const regexp = new RegExp(
      normalize(methods.safeString(state.search).replace(/\s+/g, " ").trim()),
      "i"
    );
    return methods
      .sortBy()
      .filter((item) => regexp.test(normalize(item[props.searchBy])));
  }

  handleChangeProvincia(value) {
    this.selectLocMethods.clearAll();
    document.getElementById("Localidad").value = "";
    if (value) {
      document.getElementById("Provincia").value = value.nombre;
      fetch("/api/mapa/localidades/?idProv=" + value.id)
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            idProvincia: value.id,
            provincia: value.nombre,
            provinciaValido: true,
            idLocalidad: -1,
            localidad: "",
            localidadValido: false,
            localidades: data,
          });
        });
    } else {
      this.setState({
        idProvincia: -1,
        provincia: "",
        provinciaValido: false,
        idLocalidad: -1,
        localidad: "",
        localidadValido: false,
        localidades: [],
      });
    }
  }

  handleChangeLocalidad(value) {
    if (value) {
      document.getElementById("Localidad").value = value.nombre;
      this.setState({
        idLocalidad: value.id,
        localidad: value.nombre,
        localidadValido: true,
      });
    }
  }

  customContentRenderer({ props, methods }) {
    return (
      <React.Fragment>
        <div
          className="posventa_select"
          style={{ width: "100%", height: "100%"}}
        >
          <input
            type="text"
            id={props.placeholder}
            readOnly={!props.searchable}
            onChange={(event) => methods.setSearch(event)}
            placeholder={props.placeholder}
            style={{
              border: "none",
              borderColor: "transparent",
              outline: "none",
              caretColor: "transparent",
              width: "100%",
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const mobile =
      window.innerWidth * 0.8 < parseInt(calcularPx(1163).slice(0, -2));
    const styleCampo = {
    };
    let f = new Date();
    const offset = f.getTimezoneOffset();
    f = new Date(f.getTime() - offset * 60 * 1000);
    let fecha = f.toISOString().split("T")[0];
    return (
      <React.Fragment>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: 300,
          }}
        >
          <img
            src={require("../../res/banners/posventa_" + (mobile ? "mobile":"desktop" ) + ".jpg")}
            style={{
              position: "relative",
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
              height: 300,
              zIndex: -1,
            }}
          />
          <img
            src={require("../../res/banners/posventa_titulo.png")}
            style={{
              position: "absolute",
              objectFit: "cover",
              objectPosition: "left",
              width: "80%",
              maxWidth: 466,
              height: "auto",
              top: 113,
              left: "10%",
              zIndex: 0,
            }}
          />
        </div>
        {this.state.nroReclamo == -1 ? (
          <div style={{width: "80%", marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center"}}>
            <div style={{width: "100%", height: "2vw"}}/>
            <div className="posventa_titulo">Antes de solicitar el servicio posventa te recomendamos <b>verificar</b></div>
            <div style={{width: "100%", height: "3vw"}}/>
            <div style={{display: "flex", width: "100%", maxWidth: 1100, flexDirection: "row", flexWrap: "wrap", gap: 130}}>
              <div className="posventa_item">
                <img src={require("../../res/iconos/posventa_1.png")}
                    style={{
                      position: "relative",
                      objectFit: "cover",
                      objectPosition: "center",
                      width: 160,
                    }} 
                />
                <div className="posventa_texto1">Factura de compra</div>
                <div className="posventa_texto2">Contar con la factura de compra. La misma es necesaria para comprobar la vigencia de la garantía.</div>
              </div>
              <div className="posventa_item">
                <img src={require("../../res/iconos/posventa_2.png")}
                    style={{
                      position: "relative",
                      objectFit: "cover",
                      objectPosition: "center",
                      width: 160,
                    }} 
                />
                <div className="posventa_texto1">Datos del instalador</div>
                <div className="posventa_texto2"> Deberá presentar datos personales y número de matrícula del instalador en caso de que el equipo sea a gas.</div>
              </div>
              <div className="posventa_item">
                <img src={require("../../res/iconos/posventa_3.png")}
                    style={{
                      position: "relative",
                      objectFit: "cover",
                      objectPosition: "center",
                      width: 160,
                    }} 
                />
                <div className="posventa_texto1">Mantenimiento</div>
                <div className="posventa_texto2">Conservar el comprobante de mantenimiento o cambio del ánodo, efectuado por un profesional.</div>
              </div>
            </div>
            <div style={{width: "100%", height: "2vw"}}/>
            {!this.state.mostrar ? 
            <button className="posventa_boton" onClick={() => this.setState({mostrar: true})}>Ir al formulario</button>
            :
            <div
              className="posventa_recuadro"
            >
              <h1
                className="posventa_recuadro_aclaracion"
              >
                Los campos marcados con{" "}
                <span style={{ color: "#9d1c21" }}>*</span> son obligatorios.
              </h1>
              <div className="posventa_recuadro_titulo">
                  Datos personales
              </div>
              <div style={{display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between", gap: 24}}>
                  <div className="posventa_campo_titulo">
                    Nombre<span style={{ color: "#9d1c21" }}>*</span>
                    <input
                      className="posventa_campo"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido =
                          0 < event.target.value.length &&
                          event.target.value.length <= 20;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          nombre: event.target.value,
                          nombreValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div className="posventa_campo_titulo">
                      Apellido<span style={{ color: "#9d1c21" }}>*</span>
                      <input
                      className="posventa_campo"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido =
                          0 < event.target.value.length &&
                          event.target.value.length <= 20;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          apellido: event.target.value,
                          apellidoValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div className="posventa_campo_titulo">
                      DNI (sin puntos)<span style={{ color: "#9d1c21" }}>*</span>
                      <input
                      className="posventa_campo"
                      type="text"
                      pattern="[0-9]*"
                      style={styleCampo}
                      value={this.state.dni}
                      onChange={(event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        let value = event.target.value;
                        let valido = 6 < value.length && value.length <= 9;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          dni: value,
                          dniValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
              </div>
              <div style={{display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between", gap: 24}}>
                  <div className="posventa_campo_titulo">
                      Email<span style={{ color: "#9d1c21" }}>*</span>
                      <input
                      className="posventa_campo"
                      type="email"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido = this.validarMail(event.target.value);
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          email: event.target.value,
                          emailValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div className="posventa_campo_titulo">
                      Celular<span style={{ color: "#9d1c21" }}>*</span>
                      <input
                      className="posventa_campo"
                      type="tel"
                      style={styleCampo}
                      value={this.state.celular}
                      onChange={(event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9\+\-\s]/g,
                          ""
                        );
                        let value = event.target.value;
                        let valido = 6 < value.length && value.length <= 30;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          celular: value,
                          celularValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
              </div>
              <h1 className="posventa_recuadro_titulo">
                  Domicilio
                </h1>
              <div style={{display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between", gap: 24}}>
                  <div className="posventa_campo_titulo">
                      Calle<span style={{ color: "#9d1c21" }}>*</span>
                    <input
                      className="posventa_campo"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido =
                          0 < event.target.value.length &&
                          event.target.value.length <= 25;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          calle: event.target.value,
                          calleValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div className="posventa_campo_titulo">
                      Número<span style={{ color: "#9d1c21" }}>*</span>
                    <input
                      className="posventa_campo"
                      type="text"
                      pattern="[0-9]*"
                      style={styleCampo}
                      value={this.state.altura}
                      onChange={(event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        let value = event.target.value;
                        let valido = 0 < value.length && value.length <= 6;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          altura: value,
                          alturaValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div className="posventa_campo_titulo">
                    Piso
                    <input
                      className="posventa_campo"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido = event.target.value.length < 10;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          piso: event.target.value,
                          pisoValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div className="posventa_campo_titulo">
                    Departamento
                    <input
                      className="posventa_campo"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido = event.target.value.length < 10;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          dpto: event.target.value,
                          dptoValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div className="posventa_campo_titulo">
                      Provincia<span style={{ color: "#9d1c21" }}>*</span>
                    <Select
                      className="posventa_campo"
                      style={{
                        paddingLeft: 10,
                        border: "1px solid #cccbcb",
                        borderRadius: 7,
                        backgroundColor: "#FFFFFF"
                    }}
                      color="#9d1c21"
                      placeholder="Provincia"
                      searchable={true}
                      separator={true}
                      noDataLabel={"No hay resultados."}
                      handleKeyDownFn={({ methods, state }) => {
                        let input = document.getElementById("Provincia");
                        input.focus();
                        if (state.values[0]) {
                          methods.clearAll();
                          input.value = "";
                        }
                      }}
                      separatorRenderer={({ methods }) => {
                        this.selectProvMethods = methods;
                      }}
                      dropdownGap={0}
                      labelField={"nombre"}
                      searchBy={"nombre"}
                      valueField={"id"}
                      options={this.state.provincias}
                      onChange={(values) =>
                        this.handleChangeProvincia(values[0])
                      }
                      contentRenderer={this.customContentRenderer}
                      searchFn={this.customSearch}
                    />
                  </div>
                  <div className="posventa_campo_titulo">
                      Localidad<span style={{ color: "#9d1c21" }}>*</span>
                    <Select
                      className="posventa_campo"
                      style={{
                        paddingLeft: 10,
                        border: "1px solid #cccbcb",
                        borderRadius: 7,
                        backgroundColor: "#FFFFFF"
                    }}
                      color="#9d1c21"
                      placeholder="Localidad"
                      searchable={true}
                      separator={true}
                      noDataLabel={"No hay resultados."}
                      handleKeyDownFn={({ methods, state }) => {
                        let input = document.getElementById("Localidad");
                        input.focus();
                        if (state.values[0]) {
                          methods.clearAll();
                          input.value = "";
                        }
                      }}
                      separatorRenderer={({ methods }) => {
                        this.selectLocMethods = methods;
                      }}
                      dropdownGap={0}
                      labelField={"nombre"}
                      searchBy={"nombre"}
                      valueField={"id"}
                      options={this.state.localidades}
                      onChange={(values) =>
                        this.handleChangeLocalidad(values[0])
                      }
                      contentRenderer={this.customContentRenderer}
                      searchFn={this.customSearch}
                    />
                  </div>
                  <div className="posventa_campo_titulo">
                      Código postal<span style={{ color: "#9d1c21" }}>*</span>
                    <input
                      className="posventa_campo"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido =
                          0 < event.target.value.length &&
                          event.target.value.length <= 10;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          codpostal: event.target.value,
                          codpostalValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
              </div>

              <h1 className="posventa_recuadro_titulo">
                  Datos del producto
              </h1>
              <div style={{display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between", gap: 24}}>                  
                  <div className="posventa_campo_titulo">
                      Producto<span style={{ color: "#9d1c21" }}>*</span>
                      <input
                    className="posventa_campo"
                    type="text"
                    style={styleCampo}
                    onChange={(event) => {
                      let valido =
                        0 < event.target.value.length;
                      if (valido) {
                        event.target.style.borderColor = "#cccbcb";
                      } else {
                        event.target.style.borderColor = "#9d1c21";
                      }
                      this.setState({
                        producto: event.target.value,
                        productoValido: valido,
                        error: null,
                      });
                    }}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        document.getElementById("botonEnviar").click();
                      }
                    }}
                  />
                  </div>
                  <div className="posventa_campo_titulo">
                      Nº de serie<span style={{ color: "#9d1c21" }}>*</span>
                    <input
                      className="posventa_campo"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido =
                          0 < event.target.value.length &&
                          event.target.value.length <= 30;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          serie: event.target.value,
                          serieValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div className="posventa_campo_titulo">
                      Fecha de compra<span style={{ color: "#9d1c21" }}>*</span>
                    <input
                      type="date"
                      className="posventa_campo"
                      style={styleCampo}
                      max={fecha}
                      onChange={(event) => {
                        let fn = new Date(event.target.value);
                        let valido = fn <= f;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          fecha_compra: event.target.value,
                          fecha_compraValido: valido,
                          error: null,
                        });
                      }}
                    />
                </div>
                <div className="posventa_campo_titulo">
                    Comercio<span style={{ color: "#9d1c21" }}>*</span>
                  <input
                    className="posventa_campo"
                    type="text"
                    style={styleCampo}
                    onChange={(event) => {
                      let valido =
                        0 < event.target.value.length &&
                        event.target.value.length <= 30;
                      if (valido) {
                        event.target.style.borderColor = "#cccbcb";
                      } else {
                        event.target.style.borderColor = "#9d1c21";
                      }
                      this.setState({
                        comercio: event.target.value,
                        comercioValido: valido,
                        error: null,
                      });
                    }}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        document.getElementById("botonEnviar").click();
                      }
                    }}
                  />
                </div>
              </div>
              <div style={{display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between", gap: 24}}>
                <div className="posventa_campo_titulo">
                        Factura de compra<span style={{ color: "#9d1c21" }}>*</span>
                        <button
                        onClick={() =>
                          document.getElementById("archivoFactura").click()
                        }
                        className="posventa_campo"
                      >
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        {this.state.nombreFactura}
                        {!this.state.archivoFactura && <IcoMas width={24} height={24}/>}
                        </div>
                        </button>
                      <input
                        id="archivoFactura"
                        type="file"
                        style={{
                          display: "block",
                          opacity: 0,
                          width: 0,
                          height: 1,
                        }}
                        onChange={(event) => {
                          let file = event.target.files[0];
                          if (file?.size <= 15728640) {
                            let tipos = [
                              "PDF",
                              "BMP",
                              "GIF",
                              "JPEG",
                              "JPG",
                              "PNG",
                            ];
                            let ext = file.name
                              .split(".")
                              .slice(-1)[0]
                              .toUpperCase();
                            if (tipos.find((e) => e === ext)) {
                              this.setState({
                                archivoFactura: file,
                                nombreFactura: file.name,
                              });
                            } else {
                              alert(
                                "Los tipos soportados son pdf, bmp, gif, jpeg, jpg y png."
                              );
                              event.target.files = [];
                              this.setState({
                                archivoFactura: null,
                                nombreFactura: "Elegir archivo",
                              });
                            }
                          } else {
                            alert("Cada archivo debe pesar menos de 15MB.");
                            event.target.files = [];
                            this.setState({
                              archivoFactura: null,
                              nombreFactura: "Elegir archivo",
                            });
                          }
                        }}
                      />
                </div>
                <div className="posventa_campo_titulo">
                        Imagen del producto completo<span style={{ color: "#9d1c21" }}>*</span>
                        <button
                        onClick={() =>
                          document.getElementById("archivoImagen1").click()
                        }
                        className="posventa_campo"
                      >
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        {this.state.nombreImagen1}
                        {!this.state.archivoImagen1 && <IcoMas width={24} height={24}/>}
                        </div>
                        </button>
                        <input
                      id="archivoImagen1"
                      type="file"
                      style={{
                        display: "block",
                        opacity: 0,
                        width: 0,
                        height: 1,
                      }}
                      onChange={(event) => {
                        let file = event.target.files[0];
                        if (file?.size <= 15728640) {
                          let tipos = ["BMP", "GIF", "JPEG", "JPG", "PNG"];
                          let ext = file.name
                            .split(".")
                            .slice(-1)[0]
                            .toUpperCase();
                          if (tipos.find((e) => e === ext)) {
                            this.setState({
                              archivoImagen1: file,
                              nombreImagen1: file.name,
                            });
                          } else {
                            alert(
                              "Los tipos soportados son bmp, gif, jpeg, jpg y png."
                            );
                            event.target.files = [];
                            this.setState({
                              archivoImagen1: null,
                              nombreImagen1: "Elegir imagen",
                            });
                          }
                        } else {
                          alert("Cada archivo debe pesar menos de 15MB.");
                          event.target.files = [];
                          this.setState({
                            archivoImagen1: null,
                            nombreImagen1: "Elegir imagen",
                          });
                        }
                      }}
                    />
                </div>
                <div className="posventa_campo_titulo">
                        Imagen del tiraje o salida<span style={{ color: "#9d1c21" }}>*</span>
                        <button
                        onClick={() =>
                          document.getElementById("archivoImagen2").click()
                        }
                        className="posventa_campo"
                      >
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        {this.state.nombreImagen2}
                        {!this.state.archivoImagen2 && <IcoMas width={24} height={24}/>}
                        </div>
                        </button>
                        <input
                      id="archivoImagen2"
                      type="file"
                      style={{
                        display: "block",
                        opacity: 0,
                        width: 0,
                        height: calcularPx(1),
                      }}
                      onChange={(event) => {
                        let file = event.target.files[0];
                        if (file.size <= 15728640) {
                          let tipos = ["BMP", "GIF", "JPEG", "JPG", "PNG"];
                          let ext = file.name
                            .split(".")
                            .slice(-1)[0]
                            .toUpperCase();
                          if (tipos.find((e) => e === ext)) {
                            this.setState({
                              archivoImagen2: file,
                              nombreImagen2: file.name,
                            });
                          } else {
                            alert(
                              "Los tipos soportados son bmp, gif, jpeg, jpg y png."
                            );
                            event.target.files = [];
                            this.setState({
                              archivoImagen2: null,
                              nombreImagen2: "Elegir imagen",
                            });
                          }
                        } else {
                          alert("Cada archivo debe pesar menos de 15MB.");
                          event.target.files = [];
                          this.setState({
                            archivoImagen2: null,
                            nombreImagen2: "Elegir imagen",
                          });
                        }
                      }}
                    />
                </div>
                <div className="posventa_recuadro_nota">
                *El Número de Serie se encuentra dentro de una etiqueta pegada en el equipo.
                </div>
                <div className="posventa_recuadro_nota">
                *Cuando tome una foto del producto, ubique la cámara frente a él abarcando la totalidad del mismo.
                </div>
                <div className="posventa_recuadro_nota">
                *Cuando tome una foto de la salida o tiraje del producto, recuerde que en el caso de los convectores se encuentra en la parte posterior o lateral, y en los termotanques en la parte superior o inferior dependiendo del modelo.
                </div>
                <div className="posventa_campo_titulo">
                    Falla<span style={{ color: "#9d1c21" }}>*</span>
                    <textarea
                      className="posventa_campo"
                      placeholder="Describa detalladamente  la falla que presenta su equipo"
                      type="text"
                      style={{
                        height: 200,
                      }}
                      onChange={(event) => {
                        let valido = event.target.value.length > 0;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          falla: event.target.value,
                          fallaValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                </div>
              </div>    
              <h1 className="posventa_recuadro_titulo">
                  Datos del instalador matriculado
              </h1>
              <div style={{display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between", gap: 24}}>
                <div className="posventa_campo_titulo">
                    Nombre<span style={{ color: "#9d1c21" }}>*</span>
                    <input
                      className="posventa_campo"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido = event.target.value.length > 0;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          nombreInstalador: event.target.value,
                          nombreInstaladorValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                </div>
                <div className="posventa_campo_titulo">
                    Apellido<span style={{ color: "#9d1c21" }}>*</span>
                    <input
                      className="posventa_campo"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido = event.target.value.length > 0;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          apellidoInstalador: event.target.value,
                          apellidoInstaladorValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                </div>
                <div className="posventa_campo_titulo">
                    Matrícula<span style={{ color: "#9d1c21" }}>*</span>
                    <input
                      className="posventa_campo"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido = event.target.value.length > 0;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          matriculaInstalador: event.target.value,
                          matriculaInstaladorValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                </div>
                <div className="posventa_recuadro_nota">
                *En el caso de ser un producto eléctrico, completar el campo <b>"Matrícula"</b> con la palabra <b>"eléctrico"</b>.
                </div>
              </div> 
              <div style={{marginTop: 48,width: "100%", display: "flex", justifyContent: "center"}}>
              <button
                id="botonEnviar"
                className="posventa_recuadro_boton"
                disabled={
                  !(
                    this.state.alturaValido &&
                    this.state.apellidoValido &&
                    this.state.calleValido &&
                    this.state.celularValido &&
                    this.state.codpostalValido &&
                    this.state.dptoValido &&
                    this.state.fecha_compraValido &&
                    this.state.localidadValido &&
                    this.state.emailValido &&
                    this.state.productoValido &&
                    this.state.nombreValido &&
                    this.state.serieValido &&
                    this.state.pisoValido &&
                    this.state.provinciaValido &&
                    this.state.nombreInstaladorValido &&
                    this.state.apellidoInstaladorValido &&
                    this.state.matriculaInstaladorValido &&
                    this.state.error == null &&
                    !this.state.enviando
                  )
                }
                onClick={() => this.enviar()}
              >
                Enviar
              </button>
              </div>
            </div>
            }
          </div>
        ) : (
          <Gracias tipo="caso" nro={this.state.nroReclamo} mensaje1="Su solicitud fue registrada" mensaje2="Nuestro equipo posventa validará los datos y derivará el caso a unos de los Service Autorizados." aclaracion="Recuerde revisar la casilla de su correo electrónico."/>
        )}

        <div style={{width: "100%", height: "7vw"}}/>
      </React.Fragment>
    );
  }
}

export default Posventa;
