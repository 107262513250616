import React, { Component } from "react";
import background from "../../res/banners/bg_atencion.jpg";
import posventa from "../../res/iconos/posventa.png";
import posventa_hover from "../../res/iconos/posventa_hover.png";
import pf from "../../res/iconos/pf.png";
import pf_hover from "../../res/iconos/pf_hover.png";
import garantia from "../../res/iconos/garantia.png";
import garantia_hover from "../../res/iconos/garantia_hover.png";
import tutoriales from "../../res/iconos/tutoriales.png";
import tutoriales_hover from "../../res/iconos/tutoriales_hover.png";

import { Link } from "react-router-dom";

class Atencion extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

  render() {
    return (
      <div style={{backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundPosition: "center", backgroundSize: "cover", backgroundColor: '#a8a9ad'}}>
        <div style={{ width: "100%", height: "8vw" }} />
        <div style={{width: "80%", marginLeft: "auto", marginRight: "auto"}}>
            <div className="atencion_titulo">¿En qué podemos ayudarte?</div>
        </div>
        <div style={{ width: "100%", height: "7vw" }} />
        <div style={{width: "80%", marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "space-between", alignContent: "stretch", gap: 24, flexWrap: "wrap"}}>
          <Link to="/posventa">
            <div 
                className="atencion_boton" 
                style={{ '--my-image': `url(${posventa})`, '--hover-image': `url(${posventa_hover})` }}
            />
          </Link>
          <Link to="/preguntas_frecuentes">
            <div 
                className="atencion_boton" 
                style={{ '--my-image': `url(${pf})`, '--hover-image': `url(${pf_hover})` }}
            />
          </Link>
          <Link to="/atencion_al_cliente/videos">
            <div 
                className="atencion_boton" 
                style={{ '--my-image': `url(${tutoriales})`, '--hover-image': `url(${tutoriales_hover})` }}
            />
          </Link>
          <Link to="/registrar_producto">
            <div 
                className="atencion_boton" 
                style={{ '--my-image': `url(${garantia})`, '--hover-image': `url(${garantia_hover})` }}
            />
          </Link>
        </div>
        <div style={{width: "100%", height: "7vw"}}/>
    </div>
    );
  }
}

export default Atencion;
