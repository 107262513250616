import React, { Component } from "react";
import { Admin, Resource, defaultTheme, CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";
import dataProvider from "./herramientas/dataProvider.js";
import { i18nProvider } from "./herramientas/i18nProvider.js";
import authProvider from "./herramientas/authProvider.js";
import ErrorAuth from "./herramientas/errorAuth.js";

import {
  calefactoresList,
  calefactoresEdit,
  calefactoresCreate,
} from "./list-create-edit/calefactores.js";
import {
  termotanquesList,
  termotanquesEdit,
  termotanquesCreate,
} from "./list-create-edit/termotanques.js";
import {
  hornosElectricosList,
  hornosElectricosEdit,
  hornosElectricosCreate,
} from "./list-create-edit/hornosElectricos.js";
import {
  puntosDeVentaList,
  puntosDeVentaEdit,
  puntosDeVentaCreate,
} from "./list-create-edit/puntosDeVenta.js";
import {
  preguntasFrecuentesCreate,
  preguntasFrecuentesEdit,
  preguntasFrecuentesList,
} from "./list-create-edit/preguntasFrecuentes.js";
import {
  zonaClientesCreate,
  zonaClientesEdit,
  zonaClientesList,
} from "./list-create-edit/zonaClientes.js";

const theme = {
  ...defaultTheme,
  palette: {
    primary: {
      main: "#9d1c21",
    },
    secondary: {
      main: "#9d1c21",
    },
  },
};

const Administracion = () => (
  <Admin
    basename="/admin"
    locale="es"
    i18nProvider={i18nProvider}
    dataProvider={dataProvider}
    authProvider={authProvider}
    theme={theme}
  >
    <Resource
      name="calefaccion"
      list={calefactoresList}
      edit={calefactoresEdit}
      create={calefactoresCreate}
      options={{ label: "Calefacción" }}
    />

    <Resource
      name="agua_caliente"
      list={termotanquesList}
      edit={termotanquesEdit}
      create={termotanquesCreate}
      options={{ label: "Agua caliente" }}
    />

    <Resource
      name="cocina"
      list={hornosElectricosList}
      edit={hornosElectricosEdit}
      create={hornosElectricosCreate}
      options={{ label: "Cocina" }}
    />

    <Resource
      name="puntosDeVenta"
      list={puntosDeVentaList}
      edit={puntosDeVentaEdit}
      create={puntosDeVentaCreate}
      options={{ label: "Puntos de venta" }}
    />

    <Resource
      name="preguntasFrecuentes"
      list={preguntasFrecuentesList}
      edit={preguntasFrecuentesEdit}
      create={preguntasFrecuentesCreate}
      options={{ label: "Preguntas frecuentes" }}
    />

    <Resource
      name="zonaClientes"
      list={zonaClientesList}
      edit={zonaClientesEdit}
      create={zonaClientesCreate}
      options={{ label: "Zona Clientes" }}
    />
    <CustomRoutes>
      <Route path="/sesionExpirada" element={<ErrorAuth />} />
    </CustomRoutes>
  </Admin>
);

export default Administracion;
