import React, { Component } from "react";
import { useNavigate, useParams } from "react-router-dom";
import calcularPx from "../general/calcularPx";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";

function withRouterAndNavigate(Component) {
  function ComponentWithRouterAndNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterAndNavigate;
}

class Descargas extends Component {
  state = { archivos: [] };

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    fetch("/api/clientes/archivos?seccion=" + this.props.params.seccion)
      .then((res) => res.json())
      .then(async (data) => {
        if (data.error) {
          this.props.cerrarSesion(
            "Tu sesión expiró. Por favor ingresá de nuevo."
          );
        } else {
          let archivos = [];
          const icono = require("../../res/iconos/archivo.png");
          let i = 0;
          while (i < data.length) {
            data[i].nombre = data[i].filePath.split("/").slice(-1)[0];
            let ext = data[i].nombre.slice().split(".").slice(-1)[0];
            let imageTypes = ["bmp", "gif", "jpeg", "jpg", "png"];
            if (!imageTypes.find((e) => e === ext)) {
              data[i].src = icono;
            } else {
              const res = await fetch(data[i].filePath);
              const blob = await res.blob();
              data[i].img = true;
              data[i].src = URL.createObjectURL(blob);
            }
            archivos.push(data[i]);
            i++;
          }
          this.setState({ archivos: archivos });
        }
      });
  }

  async descargar(a) {
    if (a.img) {
      a.url = a.src;
    } else {
      const res = await fetch(a.filePath);
      const blob = await res.blob();
      a.url = URL.createObjectURL(blob);
    }
    this.setState({}, () => {
      document.getElementById("descargar" + a.id).click();
    });
  }

  render() {
    const a = parseInt(
      (window.innerWidth*0.8) /
        parseInt(calcularPx(193).slice(0, -2))
    );
    const b = this.state.archivos.length;
    const cantColumnas = (a + b - Math.abs(a - b)) / 2;
    return (
      <React.Fragment>
        <div
          className="tituloClientes"
          style={{
            width: "80%",
            marginRight: "auto",
            marginLeft: "auto",
            marginTop: calcularPx(44),
            marginBottom: calcularPx(91.23),
            textAlign: "center",
          }}
        >
          {this.props.params.seccion}
        </div>
        <div
          style={{
            position: "relative",
            display: "grid",
            flexShrink: 0,
            gridAutoColumns: calcularPx(160),
            gridTemplateColumns: "auto ".repeat(cantColumnas),
            gridAutoRows: calcularPx(200),
            placeItems: "start",
            placeContent: "center",
            width: "100%",
            gridGap: calcularPx(33),
          }}
        >
          {this.state.archivos.map((a) => (
            <React.Fragment>
              <button
                onClick={() => this.descargar(a)}
                style={{ border: "none", background: "transparent" }}
              >
                <div
                  style={{ height: calcularPx(160), width: calcularPx(160) }}
                >
                  <img
                    src={a.src}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <h1
                  className="nombreArchivo"
                  style={{
                    marginTop: calcularPx(8),
                    maxWidth: calcularPx(160),
                    textAlign: "center",
                  }}
                >
                  {a.nombre}
                </h1>
              </button>
              <a
                id={"descargar" + a.id}
                href={a.url}
                download={a.nombre}
                style={{ display: "none" }}
              />
            </React.Fragment>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default withCookies(withRouterAndNavigate(Descargas));
