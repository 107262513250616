import React, { Component } from "react";
import background from "../../res/banners/sobre_nosotros_5.png";
import { ReactComponent as Frase } from "../../res/banners/sobre_nosotros_footer.svg";

class SobreNosotros extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "43vw",
          }}
        >
          <img
            src={require("../../res/banners/sobre_nosotros_1.png")}
            style={{
              position: "relative",
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
              height: "43vw",
              zIndex: -1,
            }}
          />
          <img
            src={require("../../res/banners/sobre_nosotros_1_texto.png")}
            style={{
              position: "absolute",
              objectFit: "cover",
              objectPosition: "center",
              width: 969,
              maxWidth: "80%",
              height: "auto",
              bottom: 41,
              left: 0,
              right: 0,
              margin: "auto",
              zIndex: 0,
            }}
          />
        </div>
        <div className="sobre_nosotros_gradiente">
          Coppens es una empresa familiar fundada en el año 1967 en Mar del Plata dedicada a la fabricación de calefactores y termotanques.
          <div style={{width: "100%", height: 28}}/>
          Pasamos de ser un modesto taller artesanal que producía entre 250 a 300 unidades/mes ubicado en la Avenida Champagnat, a una planta industrial que produce entre 15.000/20.000 unidades/mes, teniendo una superficie de más 40.000 metros cuadrados y personal de 230 empleados. Nuestro primer producto fue un calefactor a combustible líquido, que para aquella época se trataba de un producto original, revolucionario y de buena calidad.
          <div style={{width: "100%", height: 28}}/>
          Hoy en día, somos líderes en el mercado de calefactores a gas y exportamos a países de Latinoamérica. Además, hemos ampliado nuestro catálogo a más de 30 productos distintos y buscamos constantemente evolucionar con nuevos desarrollos que satisfagan las necesidades de los hogares argentinos.
        </div>
        <div className="sobre_nosotros_postales">
            <img
              src={require("../../res/banners/sobre_nosotros_2.png")}
              style={{
                position: "relative",
                objectFit: "cover",
                objectPosition: "center",
                width: "auto",
                height: "auto",
                rotate: "-8.76",
                margin: "auto",
                zIndex: 0,
              }}
            />
            <img
              src={require("../../res/banners/sobre_nosotros_3.png")}
              style={{
                position: "relative",
                objectFit: "cover",
                objectPosition: "center",
                width: "auto",
                height: "auto",
                rotate: "3.77",
                margin: "auto",
                zIndex: 0,
              }}
            />
            <img
              src={require("../../res/banners/sobre_nosotros_4.png")}
              style={{
                position: "relative",
                objectFit: "cover",
                objectPosition: "center",
                width: "auto",
                height: "auto",
                rotate: "4.57",
                margin: "auto",
                zIndex: 0,
              }}
            />
        </div>
        <div
          style={{
            position: "relative",
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <div style={{width: "100%", height: "6vw"}}/>
          <div className="sobre_nosotros_calidad_titulo">Calidad y mejora continua</div>
          <div style={{width: "100%", height: "7vw"}}/>
          <div style={{display: "flex", gap: 56, flexWrap: "wrap", justifyContent: "center"}}>
            <img
              src={require("../../res/banners/iso.png")}
              style={{
                position: "relative",
                flexGrow: 0, 
                flex: 338,
                objectFit: "fit",
                objectPosition: "center",
                width: 338,
                maxWidth: "100%",
                height: 319,
                maxHeight: "76vw",
                margin: "auto",
                zIndex: 0,
              }}
            />
            <div style={{flex: 760}}>
              <div className="sobre_nosotros_calidad_texto">En Coppens creemos que la mejor forma de llevar tranquilidad a nuestros usuarios es ofreciendo productos eficientes, confiables y de calidad. Por eso, nos centramos en la mejora continua de productos y procesos, y en mantener altas normas de calidad.</div>
              <div className="sobre_nosotros_calidad_texto">Participamos en el programa Kaizen Tango de Mejora Continua, donde fuimos seleccionados por el INTI y la JIKA para un proyecto de cooperación entre Japón y Argentina.</div>
              <div className="sobre_nosotros_calidad_texto">El método Kaizen, de origen japonés, significa "mejoras continuas" y se compone de los vocablos "kai" (cambio) y "zen" (para algo mejor), lo que beneficia a los usuarios de nuestros productos.</div>
            </div>
          </div>
          <div style={{width: "100%", height: "7vw"}}/>
        </div>
        <div 
          style={{
            backgroundImage: `url(${background})`, 
            backgroundRepeat: 'no-repeat', 
            backgroundPositionX:"right", 
            backgroundPositionY: "top", 
            backgroundSize: "cover", 
            backgroundColor: '#303439',
            paddingLeft: "10%",
            paddingRight: "10%",
            paddingTop: "3vw",
            paddingBottom: "5vw",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "right"
          }}>
            <div className="sobre_nosotros_sustentabilidad_titulo">Sustentabilidad</div>
            <div style={{maxWidth: 720}}>
              <div className="sobre_nosotros_sustentabilidad_subtitulo">¿Sabías que Coppens es una empresa comprometida con el cuidado del medio ambiente?</div>
              <ul className="sobre_nosotros_sustentabilidad_texto">
                <li>Usamos cajas reciclables.</li>
                <li>Empleamos papel cerámico en nuestros equipos de calefacción, sustituyendo materiales cancerígenos y nocivos para la salud.</li>
                <li>Controlamos la emisión de gases de nuestros equipos de calefacción y termotanques según la reglamentación vigente (ENERGAS).</li>
                <li>Reducimos el consumo de energía con equipos de alta eficiencia y rendimiento.</li>
                <li>Utilizamos aislación de poliuretano en nuestros termotanques para conservar el agua caliente por más tiempo, lo que ahorra energía y reduce el consumo de gas.</li>
              </ul>
            </div>
        </div>
        <div style={{width: "100%", height: "6vw"}}/>
        <div className="sobre_nosotros_actualidad_titulo">Actualidad</div>
        <div style={{width: "100%", height: "6vw"}}/>
        <div 
          style={{
            width: "100%", 
            paddingLeft: "10%", paddingRight: "10%", 
            display: "flex", gap: 49, flexWrap: "wrap", 
            justifyContent: "center", justifyItems: "space-between",
            backgroundImage: 'linear-gradient(rgba(0,0,0,0) 35%, #F5F5F5 35%)'}}>
          <img
              src={require("../../res/banners/sobre_nosotros_6.png")}
              style={{
                position: "relative",
                border: "10px solid #FFFFFF",
                borderRadius: 5,
                objectFit: "cover",
                objectPosition: "center",
                width: 629,
                flex: 649,
                maxWidth: "80%",
                height: "auto",
                zIndex: 0,
              }}
          />
          <div style={{flex: 466, display: "flex", flexDirection: "column", justifyContent: "space-between", alignContent: "center", alignItems: "center", alignSelf: "stretch", gap: 30}}>
            <div>
              <div className="sobre_nosotros_actualidad_subtitulo1">En constante</div>
              <div className="sobre_nosotros_actualidad_subtitulo2_padre">
                <div className="sobre_nosotros_actualidad_subtitulo2">expansión</div>
              </div>
              <svg class="flt_svg" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <filter id="flt_tag">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="2.5" result="blur" />    
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                        <feComposite in="SourceGraphic" in2="flt_tag" operator="atop"/>
                    </filter>
                </defs>
              </svg>
            </div>
            <div className="sobre_nosotros_actualidad_texto">En Coppens, estamos ampliando nuestra infraestructura para poder producir nuevos productos. Este crecimiento nos permitirá incorporar equipos como calderas duales y hornos eléctricos, entre otros, asegurando calidad y eficiencia a nuestros clientes.</div>
          </div>
          <div style={{width: "100%", height: "7vw"}}/>
        </div>
        <div style={{width: "100%", height: "10vw"}}/>
        <div style={{width: 760, maxWidth: "80%", marginLeft: "auto", marginRight: "auto"}}>
          <Frase width="auto" height= "auto"/>
        </div>
        <div style={{width: "100%", height: "10vw"}}/>
      </React.Fragment>
    );
  }
}

export default SobreNosotros;
