import React, { Component } from "react";
import background from "../../res/banners/bg-comercios.jpg";
import Gracias from "../general/gracias";
import Select from "react-dropdown-select";

class RegistroProfesional extends Component {
    state = {
        mail: "",
        mailValido: false,
        matricula: "",
        matriculaValido: false,
        pass: "",
        passValida: false,
        passConfirmada: false,
        nombre: "",
        nombreValido: false,
        apellido: "",
        apellidoValido: false,
        autorizado: 0,
        idLocalidad: -1,
        error: null,
        provincias: [],
        localidades: [],
        registroExitoso: false,
      };
    
      componentDidMount() {
        window.scrollTo(0, 0);
        fetch("/api/mapa/provincias")
          .then((res) => res.json())
          .then((data) => {
            this.setState({ provincias: data });
          });
      }
    
      async registrar() {
        this.setState({ error: null });
        const res = await fetch("/api/cuentas/registrarProfesional", {
          method: "post",
          body: JSON.stringify({
            mail: this.state.mail,
            pass: this.state.pass,
            nombre: this.state.nombre,
            apellido: this.state.apellido,
            idLocalidad: this.state.idLocalidad,
            matricula: this.state.matricula,
          }),
          headers: { "Content-Type": "application/json" },
        });
        const data = await res.json();
        if (data.exito) {
          this.setState({ registroExitoso: true });
        } else {
          this.setState({ error: data.error, mailValido: false }); //Se supone que el mail está repetido pero podría ser cualquier cosa
        }
      }
    
      validarMail(email) {
        const emailRegex =
          /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
        if (!email) return false;
    
        if (email.length > 254) return false;
    
        let valid = emailRegex.test(email);
        if (!valid) return false;
    
        var parts = email.split("@");
        if (parts[0].length > 64) return false;
    
        var domainParts = parts[1].split(".");
        if (
          domainParts.some(function (part) {
            return part.length > 63;
          })
        )
          return false;
    
        return true;
      }
    
      handleChangeProvincia(value) {
        this.selectLocMethods.clearAll();
        document.getElementById("Localidad").value = "";
        if (value) {
          document.getElementById("Provincia").value = value.nombre;
          fetch("/api/mapa/localidades/?idProv=" + value.id)
            .then((res) => res.json())
            .then((data) => {
              this.setState({ localidades: data, idLocalidad: -1 });
            });
        } else {
          this.setState({ localidades: [], idLocalidad: -1 });
        }
      }
    
      handleChangeLocalidad(value) {
        if (value) {
          document.getElementById("Localidad").value = value.nombre;
          this.setState({ idLocalidad: value.id });
        }
      }
    
      customSearch({ props, state, methods }) {
        var normalize = (function () {
          var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
            to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
            mapping = {};
    
          for (var i = 0, j = from.length; i < j; i++)
            mapping[from.charAt(i)] = to.charAt(i);
    
          return function (str) {
            var ret = [];
            for (var i = 0, j = str.length; i < j; i++) {
              var c = str.charAt(i);
              if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
              else ret.push(c);
            }
            return ret.join("");
          };
        })();
        const regexp = new RegExp(
          normalize(methods.safeString(state.search).replace(/\s+/g, " ").trim()),
          "i"
        );
        return methods
          .sortBy()
          .filter((item) => regexp.test(normalize(item[props.searchBy])));
      }
    
      customContentRenderer({ props, methods }) {
        return (
          <React.Fragment>
            <div
              className="registro_select"
              style={{ width: "100%", height: "100%"}}
            >
              <input
                type="text"
                id={props.placeholder}
                onChange={(event) => {
                  methods.setSearch(event);
                }}
                placeholder={props.placeholder}
                style={{
                  border: "none",
                  borderColor: "transparent",
                  outline: "none",
                  caretColor: "transparent",
                  width: "100%",
                }}
              />
            </div>
          </React.Fragment>
        );
      }

  render() {
    return (
        this.state.registroExitoso ? 
    <Gracias mensaje1="Su registro fue enviado" mensaje2="Nuestro equipo del área de posventa validará los datos para dar de alta su usuario en la web." aclaracion="Recuerde revisar la casilla de su correo electrónico."/>
        :
      <div style={{backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundPosition:"center", backgroundPositionY: "top", backgroundSize: "cover", backgroundColor: '#a8a9ad'}}>
            <div style={{width: "100%", height: "5vw"}}/>
        
        <div style={{width: "80%", marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "space-between", justifyItems: "space-between", alignContent: "stretch", gap: "10vw", flexWrap: "wrap"}}>
          <div style={{width: "100%", flex: 1, alignSelf: "center"}}>
            <div className="registro_texto1">¡Convertite en Técnico Autorizado Coppens!</div>
            <div className="registro_texto2">Completá los datos, y nuestro equipo se
            pondrá en contacto.</div>
          </div>
          <div style={{maxWidth: 463, height: "auto", marginLeft: "auto", marginRight: "auto"}}>
            <div className="registro_titulo">
                Zona profesionales
            </div>
            <div style={{width: "100%", height: "2vw"}}/>
            <div className="registro_recuadro">
                <div className="registro_recuadro_titulo">
                    Registro
                </div>
                <input
                    placeholder="Nombre"
                    className="registro_campo"
                    type="text"
                    onChange={(event) => {
                    let valido = event.target.value.length > 0;
                    if (valido) {
                        event.target.style.borderColor = "#cccbcb";
                    } else {
                        event.target.style.borderColor = "#9d1c21";
                    }
                    this.setState({
                        nombre: event.target.value,
                        nombreValido: valido,
                        error: null,
                    });
                    }}
                    onKeyUp={(event) => {
                    if (event.key === "Enter") {
                        document.getElementById("botonRegistro").click();
                    }
                    }}
                />
                <input
                    placeholder="Apellido"
                    className="registro_campo"
                    type="text"
                    onChange={(event) => {
                    let valido = event.target.value.length > 0;
                    if (valido) {
                        event.target.style.borderColor = "#cccbcb";
                    } else {
                        event.target.style.borderColor = "#9d1c21";
                    }
                    this.setState({
                        apellido: event.target.value,
                        apellidoValido: valido,
                        error: null,
                    });
                    }}
                    onKeyUp={(event) => {
                    if (event.key === "Enter") {
                        document.getElementById("botonRegistro").click();
                    }
                    }}
                />
                <input
                    placeholder="Matrícula"
                    className="registro_campo"
                    type="text"
                    onChange={(event) => {
                    let valido = event.target.value.length > 0;
                    if (valido) {
                        event.target.style.borderColor = "#cccbcb";
                    } else {
                        event.target.style.borderColor = "#9d1c21";
                    }
                    this.setState({
                        matricula: event.target.value,
                        matriculaValido: valido,
                        error: null,
                    });
                    }}
                    onKeyUp={(event) => {
                    if (event.key === "Enter") {
                        document.getElementById("botonRegistro").click();
                    }
                    }}
                />
                <Select
                    className="registro_campo"
                    style={{
                        paddingLeft: 0,
                        borderLeft: "none",
                        borderRight: "none",
                        borderTop: "none",
                        borderBottom: "1px solid #5a5a5a",
                    }}
                    color="#9d1c21"
                    placeholder="Provincia"
                    searchable={true}
                    separator={true}
                    noDataLabel={"No hay resultados."}
                    handleKeyDownFn={({ methods, state }) => {
                    let input = document.getElementById("Provincia");
                    input.focus();
                    if (state.values[0]) {
                        methods.clearAll();
                        input.value = "";
                    }
                    }}
                    separatorRenderer={({ methods, state }) => {
                    this.selectProvMethods = methods;
                    }}
                    dropdownGap={0}
                    labelField={"nombre"}
                    searchBy={"nombre"}
                    valueField={"id"}
                    options={this.state.provincias}
                    onChange={(values) => this.handleChangeProvincia(values[0])}
                    contentRenderer={this.customContentRenderer}
                    searchFn={this.customSearch}
                />
                <Select
                    className="registro_campo"
                    style={{
                        paddingLeft: 0,
                        borderLeft: "none",
                        borderRight: "none",
                        borderTop: "none",
                        borderBottom: "1px solid #5a5a5a",
                    }}
                    color="#9d1c21"
                    placeholder="Localidad"
                    searchable={true}
                    separator={true}
                    noDataLabel={"No hay resultados."}
                    handleKeyDownFn={({ methods, state }) => {
                    let input = document.getElementById("Localidad");
                    input.focus();
                    if (state.values[0]) {
                        methods.clearAll();
                        input.value = "";
                    }
                    }}
                    separatorRenderer={({ methods }) => {
                    this.selectLocMethods = methods;
                    }}
                    dropdownGap={0}
                    labelField={"nombre"}
                    searchBy={"nombre"}
                    valueField={"id"}
                    options={this.state.localidades}
                    onChange={(values) => this.handleChangeLocalidad(values[0])}
                    contentRenderer={this.customContentRenderer}
                    searchFn={this.customSearch}
                />
                <input
                    placeholder="E-mail"
                    className="registro_campo"
                    type="email"
                    onChange={(event) => {
                    let valido = this.validarMail(event.target.value);
                    if (valido) {
                        event.target.style.borderColor = "#cccbcb";
                    } else {
                        event.target.style.borderColor = "#9d1c21";
                    }
                    this.setState({
                        mail: event.target.value,
                        mailValido: valido,
                        error: null,
                    });
                    }}
                    onKeyUp={(event) => {
                    if (event.key === "Enter") {
                        document.getElementById("botonRegistro").click();
                    }
                    }}
                />
                <div className="registro_pregunta">
                ¿Sos Service autorizado Coppens?
                </div>
                <div className="registro_check"
                  onChange={(event) =>
                    this.setState({ autorizado: event.target.value })
                  }
                >
                  <input type="radio" value={1} name="autorizado" />
                  Si
                  <input
                    type="radio"
                    value={0}
                    defaultChecked
                    name="autorizado"
                    style={{ marginLeft: 48 }}
                  />
                  No
                </div>
                <input
                    placeholder="Contraseña"
                    className="registro_campo"
                    type="password"
                    onChange={(event) => {
                    let pass = event.target.value;
                    let valida =
                        0 < pass.length && pass.length < 16 && !pass.includes(" ");
                    if (valida) {
                        event.target.style.borderColor = "#cccbcb";
                    } else {
                        event.target.style.borderColor = "#9d1c21";
                    }
                    document.getElementById("pass2").value = "";
                    this.setState({
                        pass: event.target.value,
                        passValida: valida,
                        passConfirmada: false,
                        error: null,
                    });
                    }}
                    onKeyUp={(event) => {
                    if (event.key === "Enter") {
                        document.getElementById("botonRegistro").click();
                    }
                    }}
                />
                <input
                    placeholder="Confirmar contraseña"
                    id="pass2"
                    className="registro_campo"
                    type="password"
                    onChange={(event) => {
                    let confirmada = event.target.value === this.state.pass;
                    if (confirmada) {
                        event.target.style.borderColor = "#cccbcb";
                    } else {
                        event.target.style.borderColor = "#9d1c21";
                    }
                    this.setState({
                        passConfirmada: event.target.value === this.state.pass,
                        error: null,
                    });
                    }}
                    onKeyUp={(event) => {
                    if (event.key === "Enter") {
                        document.getElementById("botonRegistro").click();
                    }
                    }}
                />
                <div
                    className="registro_mensaje"
                >
                    {this.state.error?"Ya existe un usuario con ese Email.":""}
                </div>
                <button
                    id="botonRegistro"
                    className="registro_boton"
                    disabled={
                        !(
                        this.state.matriculaValido &&
                          this.state.mailValido &&
                          this.state.passConfirmada &&
                          this.state.nombreValido &&
                          this.state.apellidoValido &&
                          this.state.idLocalidad != -1
                        )
                      }
                    onClick={() => this.registrar()}
                >
                    Enviar
                </button>
            </div>            
          </div>
        </div>
        <div style={{width: "100%", height: "7vw"}}/>
    </div>
    );
  }
}

export default RegistroProfesional;
