import React, { Component } from "react";
import { Link } from "react-router-dom";

class RRHH extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const mobile = window.innerWidth < 1280;
    return (
      <React.Fragment>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: 300,
          }}
        >
          <img
            src={require("../../res/banners/rrhh_" + (mobile ? "mobile":"desktop" ) + ".jpg")}
            style={{
              position: "relative",
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
              height: 300,
              zIndex: -1,
            }}
          />
          <img
            src={require("../../res/banners/rrhh_titulo.png")}
            style={{
              position: "absolute",
              objectFit: "cover",
              objectPosition: "left",
              width: "80%",
              maxWidth: 466,
              height: "auto",
              top: 113,
              left: "10%",
              zIndex: 0,
            }}
          />
        </div>
        <div style={{ width: "100%", height: "7vw" }} />
        <div style={{width: "80%", marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "space-between", justifyItems: "space-between", alignContent: "stretch", gap: mobile?50:120, flexWrap: "wrap"}}>
          <div style={{width: "100%", flex: 7, minWidth: mobile?undefined:450, display: "flex", flexDirection: "column", gap: 10, justifyContent: "space-between", justifyItems: "space-between"}}>
            <div className="rrhh_texto">Buscamos nuevos talentos, personas con creatividad, dinámicas y dispuestas a adaptarse a los cambios constantes que propone el mercado enfrentando así nuevos desafíos.</div>
            <div className="rrhh_texto">A través de diversas capacitaciones y programas de entrenamiento, buscamos hacer crecer a nuestros empleados, mejorando sus conocimientos y habilidades para que den un paso adelante en su carrera y así contribuir en forma mancomunada al crecimiento de la empresa. Tecnología al servicio de la calidad, trabajo en equipo, innovación y compromiso con nuestros clientes definen nuestro proyecto, y te queremos en él. <span style={{color: "#9d1c21", fontWeight: 700}}>Unite a nosotros.</span></div>
          </div>
          <div className="rrhh_recuadro" style={{width: "100%", flex: 3, minWidth: 300, height: "auto", alignItems: "center"}}>
            <div className="rrhh_recuadro_titulo">Búsquedas</div>
            <div className="rrhh_recuadro_texto">En Coppens continuamente estamos buscando a los mejores talentos de la ciudad, personas creativas y con buena disposición para trabajar en equipo. Si sos una de ellas te invitamos a cargar tu CV en nuestra plataforma.</div>
            <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
              <Link to="/recursos_humanos/cv" className="rrhh_recuadro_boton">Cargar CV</Link>
            </div>
          </div>
        </div>
        <div style={{width: "100%", height: "7vw"}}/>
    </React.Fragment>
    );
  }
}

export default RRHH;
