import React, { Component } from "react";
import { ReactComponent as IcoTick } from "../../res/iconos/tickFiltro.svg";

class Filtros extends Component {
  state = { filtros: this.props.filtros };
  
  componentDidMount() {
    this.activos = false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filtros != this.props.filtros) {
      this.setState({
        filtros: this.props.filtros,
      });
    }
  }

  handleOnChange(f, o) {
    this.props.cambiarFiltro(f, o);
  }

  render() {
    return (
      <div
        className="scrollSinBarra"
        style={{
          position: "relative",
          overflowX: "hidden",
          height: "min-content",
          maxHeight: "100%",
        }}
      >
        <h1 className="filtros_titulo">Filtros</h1>
        <button className="filtros_quitar" onClick={() => {this.props.quitar(() => {this.activos=false});}} disabled={!this.activos}>
          {"Quitar filtros"}<div>X</div>
        </button>
        {this.state.filtros.map((f,i) => {
          if (f.mostrar)
            return (
              <React.Fragment>
                <div style={{ height: 21 }} />
                <h1 className="filtros_subtitulo">{f.titulo}</h1>
                <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                  {f.opciones.map((o, j) => {
                    if (o.mostrar) {
                      this.activos = this.activos || o.checked;
                      return (
                        <button
                          className={"filtros_boton" + (o.checked?"_seleccionado":"")}
                          onClick={() =>
                            document.getElementById(`checkbox_${i}_${j}`).click()
                          }
                        >
                          <input
                            id={`checkbox_${i}_${j}`}
                            style={{display: "none"}}
                            type="checkbox"
                            value={{ min: o.valorMin, max: o.valorMax }}
                            defaultChecked={o.checked}
                            onChange={() => this.handleOnChange(f, o)}
                          />
                          {o.checked && <IcoTick width={14.5} height={9.5}/>}
                          {o.nombre}
                        </button>
                      );
                    }
                      
                  })}
                </div>
              </React.Fragment>
            );
        })}
      </div>
    );
  }
}

export default Filtros;
