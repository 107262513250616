import React, { Component } from "react";
import Mapa from "../general/mapa/mapa.jsx";

class Contacto extends Component {
  state = {
    puntos: [{ id: 1, lat: -38.0001698, lng: -57.6689611 }],
    seleccionado: { id: 1, lat: -38.0001698, lng: -57.6689611 },
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const mobile = window.innerWidth < 1280;
    return (
      <React.Fragment>
        <div style={{ width: "100%", height: mobile?30:120 }} />
        <div 
          style={{
            display: mobile?"block":"flex",
            gap: mobile?50:120,
            position: "relative",
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div style={{flex: 2, width: "100%"}}>
            <div className="contacto_titulo">
            Contacto
            </div>
            <div style={{width: "100%", height: mobile?30:60}}/>
            <div className="contacto_texto">
              Coppens S.A.
              <div style={{width: "100%", height: 30}}/>
              Calle 5 entre 2 y 4 - Parque Industrial General Savio - Ruta 88 Km. 6,5(7601) Batán, Mar del Plata, Bs.As., Argentina.
              <div style={{width: "100%", height: 30}}/>
              Tel: +54 (223) 464-6011/12
            </div>
          </div>
          {mobile && <div style={{width: "100%", height: 30}}/>}
          <div
            id="mapaConLista"
            style={{
              flex: 5,
              width: "100%",
              maxWidth: 658,
              maxHeight: 618,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Mapa
              defaultZoom={15}
              verMapa={true}
              verLista={false}
              puntos={this.state.puntos}
              seleccionado={this.state.seleccionado}
              mobile={mobile}
            />
          </div>
        </div>
        <div style={{width: "100%", height: "7vw"}}/>
      </React.Fragment>
    );
  }
}

export default Contacto;
