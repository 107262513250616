import {
  Edit,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  BooleanInput,
  List,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
  ImageInput,
  required,
} from "react-admin";

import { CustomImageField } from "../herramientas/customImageField";

const filtros = [
  <TextInput source="modelo" label="Modelo exacto" />,
  <BooleanInput source="activo" label="Activo (visible en la página)" />,
];

export const hornosElectricosList = () => (
  <List filters={filtros} title={"Hornos eléctricos"}>
    <Datagrid>
      <TextField source="id" label="ID" />
      <TextField source="linea" />
      <TextField source="modelo" />
      <NumberField source="capacidadLt" label="Capacidad en litros" />
      <EditButton />
    </Datagrid>
  </List>
);

const form = (
  <SimpleForm>
    <BooleanInput source="activo" label="Activo (visible en la página)" />
    <TextInput source="linea" validate={[required]} />
    <TextInput source="modelo" validate={[required]} />
    <TextInput source="subtitulo1" validate={[required]} />
    <TextInput source="subtitulo2" validate={[required]} />
    <TextInput multiline fullWidth source="descripcion" label="Descripción" />
    <TextInput multiline fullWidth source="caracteristicas" label="Características de calidad" />
    <ImageInput
      source="imgPath"
      label="Imagen"
      placeholder={
        <p style={{ textAlign: "left" }}>Arrastre una imágen o haga click</p>
      }
      accept="image/*"
      validate={[required]}
    >
      <CustomImageField source="src" />
    </ImageInput>
    <ImageInput
      source="manualPath"
      label="Manual"
      placeholder={
        <p style={{ textAlign: "left" }}>
          Arrastre una imágen o un archivo .pdf, o haga click
        </p>
      }
      accept="image/*,.pdf"
      validate={[required]}
    >
      <CustomImageField source="src" />
    </ImageInput>
    <NumberInput
      source="capacidadLt"
      label="Capacidad en litros"
      validate={[required]}
    />
    <NumberInput source="altoCm" label="Alto en Cm" validate={[required]} />
    <NumberInput source="anchoCm" label="Ancho en Cm" validate={[required]} />
    <NumberInput
      source="profundidadCm"
      label="Prof. en Cm"
      validate={[required]}
    />
    <NumberInput source="pesoKg" label="Peso en Kg" validate={[required]} />
    <NumberInput
      source="tempMin"
      label="Temperatura mínima"
      validate={[required]}
    />
    <NumberInput
      source="tempMax"
      label="Temperatura máxima"
      validate={[required]}
    />
    <NumberInput
      source="timerMins"
      label="Minutos del timer"
      validate={[required]}
    />
    <NumberInput
      source="potenciaWatts"
      label="Potencia en Watts"
      validate={[required]}
    />
    <NumberInput
      source="nivelesTemp"
      label="Niveles de temperatura"
      validate={[required]}
    />
    <NumberInput
      source="nivelesCoccion"
      label="Niveles de cocción"
      validate={[required]}
    />
    <NumberInput
      source="posicionesBandeja"
      label="Posiciones para bandeja"
      validate={[required]}
    />
    <BooleanInput source="stayOn" label="Stay On" />
    <BooleanInput source="portamigas" />
  </SimpleForm>
);

export const hornosElectricosCreate = () => <Create>{form}</Create>;

export const hornosElectricosEdit = () => <Edit>{form}</Edit>;
