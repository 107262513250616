import React, { Component } from "react";
import { Link } from "react-router-dom";

const SubmenuContacto = ({ mobile, ocultarMenu }) => {

  return (
    <div 
    style={mobile?
    {
      position: "relative",
      left: "5%",
      top: 20,
      width: "auto",
      background: "#ffffff",
    }:
    {
      position: "absolute",
      top: 80,
      width: 205,
      height: 144,
      display: "flex",
      justifyContent: "space-between",
      background: "#FFFFFF",
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 10,
      paddingRight: 15,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    }}>
      <div>
      <Link
          onClick={ocultarMenu}
          to="/comerciantes/registro"
        >
          <div className="categoria_submenu">Coppens a tu comercio</div>
        </Link>
        <Link
          onClick={ocultarMenu}
          to="/profesionales/registro"
        >
          <div className="categoria_submenu">Sumate como service</div>
        </Link>
        <Link
          onClick={ocultarMenu}
          to="/recursos_humanos"
        >
          <div className="categoria_submenu">Recursos humanos</div>
        </Link>
        <Link
          onClick={ocultarMenu}
          to="/contacto"
        >
          <div className="categoria_submenu">Con fábrica</div>
        </Link>
      </div>
    </div>
  );
};

export default SubmenuContacto;
