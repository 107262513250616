import React, { Component } from "react";
import { Link } from "react-router-dom";

const SubmenuAcceso = ({ mobile, ocultarMenu }) => {

  return (
    <div 
    style={mobile?
    {
      position: "relative",
      left: "5%",
      top: 20,
      width: "auto",
      background: "#ffffff",
    }:
    {
      position: "absolute",
      top: 80,
      width: 175,
      height: 82,
      display: "flex",
      justifyContent: "space-between",
      background: "#FFFFFF",
      paddingLeft: 20,
      paddingTop: 10,
      paddingBottom: 10,
      paddingRight: 20,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    }}>
      <div>
      <Link
          onClick={ocultarMenu}
          to="/comerciantes/ingresar"
        >
          <div className="categoria_submenu">Como comercio</div>
        </Link>
        <Link
          onClick={ocultarMenu}
          to="/profesionales/ingresar"
        >
          <div className="categoria_submenu">Como profesional</div>
        </Link>
      </div>
    </div>
  );
};

export default SubmenuAcceso;
