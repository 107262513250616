import React, { Component } from "react";
import background from "../../res/banners/bg-comercios.jpg";
import { Link, Navigate } from "react-router-dom";

class IngresoComerciante extends Component {
    state = {
        mail: "",
        mailValido: false,
        pass: "",
        passValida: false,
        desactivarBoton: true,
        error: false,
        mensaje: null,
        redirect: false,
    };

    async ingresar() {
        this.setState({ error: false, mensaje: null });
        const res = await fetch("/api/cuentas/ingresar/comerciante", {
            method: "post",
            body: JSON.stringify({ mail: this.state.mail, pass: this.state.pass }),
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
        });
        const data = await res.json();
        if (data.exito) {
            this.props.setLogin(data.nivel);
            this.setState({redirect: true})
        } else {
            let mensaje;
            if (data.error === "incorrectos") {
                mensaje = "Email y/o contraseña incorrectos.";
            } else {
                mensaje = "Debe verificar su cuenta. Revise su bandeja de entrada.";
            }
            this.setState({
                error: true,
                mensaje: mensaje,
                desactivarBoton: true,
            });
        }
    }

    async cambiarPass() {
        this.setState({ error: null });
        const res = await fetch("/api/cuentas/olvido/comerciante", {
            method: "post",
            body: JSON.stringify({ mail: this.state.mail }),
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
        });
        const data = await res.json();
        if (data.exito) {
            this.setState({
            error: false,
            mensaje: "Se envió un mail para cambiar su contraseña.",
            desactivarBoton: true,
            });
        } else {
            this.setState({
            error: true,
            mensaje: "El email no se encuentra registrado.",
            desactivarBoton: true,
            });
        }
    }

    validarMail(email) {
        const emailRegex =
            /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
        if (!email) return false;

        if (email.length > 254) return false;

        let valid = emailRegex.test(email);
        if (!valid) return false;

        var parts = email.split("@");
        if (parts[0].length > 64) return false;

        var domainParts = parts[1].split(".");
        if (
            domainParts.some(function (part) {
            return part.length > 63;
            })
        )
            return false;

        return true;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

  render() {
    const mobile = window.innerWidth < 1280;
    const styleCampo = {
    };
    const { error, mensaje } = this.state;
    return (
      <div style={{backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundPosition:"center", backgroundPositionY: "top", backgroundSize: "cover", backgroundColor: '#a8a9ad'}}>
        {this.state.redirect &&
        <Navigate to="/comerciantes" replace={true} />
        }
        <div style={{width: "80%", marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "space-between", justifyItems: "space-between", alignContent: "stretch", gap: mobile?"10vw":400, flexWrap: "wrap"}}>
          <div style={{width: "100%", flex: 1}}>
            <div style={{ width: "100%", height: "4vw" }} />
            <div className="ingreso_titulo">Zona comerciantes</div>
            <div style={{ width: "100%", height: "6vw" }} />
            <div className="ingreso_texto1">Si sos Comercio activo Coppens, te invitamos a ingresar para acceder a información detallada, material gráfico y merchandising.</div>
            <div style={{ width: "100%", height: "7vw" }} />
            <div className="ingreso_texto2">A través de tu inscripción podrás acceder a información de nuestros equipos a tu medida y necesidad, y podrás obtener un contacto directo con nuestro personal de venta y post-venta.</div>
          </div>
          <div style={{width: 366, height: "auto", marginLeft: "auto", marginRight: "auto"}}>
            <div style={{width: "100%", height: "7vw"}}/>
            <div className="ingreso_recuadro">
                <div className="ingreso_recuadro_titulo">
                    Iniciar sesión
                </div>
                <input
                    placeholder="E-mail"
                    className="ingreso_campo"
                    type="email"
                    onChange={(event) => {
                    let valido = this.validarMail(event.target.value);
                    if (valido) {
                        event.target.style.borderColor = "#cccbcb";
                    } else {
                        event.target.style.borderColor = "#9d1c21";
                    }
                    this.setState({
                        mail: event.target.value,
                        mailValido: valido,
                        error: false,
                        mensaje: null,
                        desactivarBoton: !(valido && this.state.passValida),
                    });
                    }}
                    onKeyUp={(event) => {
                    if (event.key === "Enter") {
                        document.getElementById("botonIngreso").click();
                    }
                    }}
                />
                <input
                    placeholder="Contraseña"
                    className="ingreso_campo"
                    type="password"
                    onChange={(event) => {
                    let valida = event.target.value.length > 0;
                    if (valida) {
                        event.target.style.borderColor = "#cccbcb";
                    } else {
                        event.target.style.borderColor = "#9d1c21";
                    }
                    this.setState({
                        pass: event.target.value,
                        passValida: valida,
                        error: false,
                        mensaje: null,
                        desactivarBoton: !(this.state.mailValido && valida),
                    });
                    }}
                    onKeyUp={(event) => {
                    if (event.key === "Enter") {
                        document.getElementById("botonIngreso").click();
                    }
                    }}
                />
                <p
                    className="ingreso_cambio"
                    onClick={() => this.cambiarPass()}
                >
                    {this.state.mailValido?"¿Olvidaste tu contraseña?":""}
                </p>
                <div
                    className="ingreso_mensaje"
                >
                    {mensaje}
                </div>
                <button
                    id="botonIngreso"
                    className="ingreso_boton"
                    disabled={this.state.desactivarBoton}
                    onClick={() => this.ingresar()}
                >
                    Ingresar
                </button>
            </div>
            <div style={{width: "100%", height: 40}}/>
            <div className="ingreso_registrarse">
                Registrarse
            </div>
            <div style={{width: "100%", height: 20}}/>
            <Link to="/comerciantes/registro" >
                <div className="ingreso_registrarse_boton">
                    Crear una cuenta
                </div>
            </Link>
            
          </div>
        </div>
        <div style={{width: "100%", height: "7vw"}}/>
    </div>
    );
  }
}

export default IngresoComerciante;
