import React, { Component } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import Producto from "./producto";

function withRouterAndNavigate(Component) {
    function ComponentWithRouterAndNavigate(props) {
      let params = useParams();
      let navigate = useNavigate();
      let location = useLocation();
      return (
        <Component
          {...props}
          params={params}
          navigate={navigate}
          location={location}
        />
      );
    }
    return ComponentWithRouterAndNavigate;
  }

class Detalle extends Component {
  state = {
    imagenes: [],
    imgPaths: []
  };

  async descargarManual() {
    const res = await fetch(this.state.manualPath);
    const blob = await res.blob();
    const url = URL.createObjectURL(blob);
    this.setState({ manualUrl: url }, () => {
      document.getElementById("botonManual").click();
    });
  }

  async getURLImagen(imgPath) {
    const res = await fetch(imgPath);
    const blob = await res.blob();
    return URL.createObjectURL(blob);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.get()
  }

  get() {
    let datos = this.props.params;
    let newState = {...this.state};
    for (let i in newState) {
      newState[i] = "";
    }
    newState.imagenes = [];
    this.setState(newState, () => {
      fetch(`/api/productos/detalle?categoria=${datos.categoria}&id=${datos.id}`)
      .then((res) => res.json())
      .then(async (data) => {
        this.setState({...datos, ...data}, async () => {
          let i = 0;
          let imagenes = [];
          while (i < data.imgPaths?.length) {
            imagenes.push(await this.getURLImagen(data.imgPaths[i]));
            i++;
          }
          this.setState({imagenes});
        })
    })
    })
    
  }

  componentDidUpdate(prevProps) {
    let datos = this.props.params;
    if (prevProps.params.categoria !== datos.categoria || prevProps.params.id !== datos.id) {
      window.scrollTo(0, 0);
      this.get();
    }
  }

  tablaAguaCaliente() {
    const {
      diametroCm, altoCm, capacidadLt,
      potencia, recuperacion,
      conexionAgua,
      energia, tipoGas, 
      aislacion, encendido, instalacion, quemador, eficiencia, termostato
    } = this.state;

    let tabla = [
      {
        nombre: "Ancho",
        valor: diametroCm + " cm"
      },
      {
        nombre: "Alto",
        valor: altoCm + " cm"
      },
      {
        nombre: "Litros",
        valor: capacidadLt + " L"
      },
      {
        nombre: "Potencia",
        valor: potencia + " kcal/h"
      },
      {
        nombre: "Recuperación",
        valor: recuperacion + " L/H"
      },
      {
        nombre: "Conexión",
        valor: conexionAgua == 0?"Inferior":conexionAgua==2?"Superior":"Superior e inferior"
      },
      energia == "g"?
      {
        nombre: "Tipo de gas",
        valor: tipoGas
      }:
      {
        nombre: "Tipo de energía",
        valor: "Eléctrico"
      },
      {
        nombre: "Aislación",
        valor: aislacion
      },
      {
        nombre: "Encendido",
        valor: encendido
      },
      {
        nombre: "Instalación",
        valor: instalacion
      },
      energia == "g"?
      {
        nombre: "Quemador",
        valor: quemador
      }:
      {
        nombre: "Termostato",
        valor: termostato?"Si":"No"
      },
      {
        nombre: "Eficiencia energética",
        valor: eficiencia
      },
    ]
    return tabla;
  }

  tablaCocina() {
    const {
      anchoCm, altoCm, profundidadCm, pesoKg,
      potenciaWatts,
      capacidadLt, 
      tempMin, tempMax, nivelesTemp,
      posicionesBandeja, bandejaCoccion, portamigas, rejillaMetalica, luz, conveccion, stayOn, timerMins
    } = this.state;

    let tabla = [
      {
        nombre: "Ancho",
        valor: anchoCm + " cm"
      },
      {
        nombre: "Alto",
        valor: altoCm + " cm"
      },
      {
        nombre: "Profundidad",
        valor: profundidadCm + " cm"
      },
      {
        nombre: "Peso",
        valor: pesoKg + " Kg"
      },
      {
        nombre: "Potencia",
        valor: potenciaWatts + " Watts"
      },
      {
        nombre: "Capacidad",
        valor: capacidadLt + " L"
      },
      {
        nombre: "Temperatura",
        valor: tempMin + "-" + tempMax + " C°"
      },
      {
        nombre: "Niveles de temp.",
        valor: nivelesTemp
      },
      {
        nombre: "Bandeja de cocción",
        valor: bandejaCoccion?"Si":"No"
      },
      {
        nombre: "Posiciones",
        valor: posicionesBandeja
      },
      {
        nombre: "Rejilla metálica",
        valor: rejillaMetalica?"Si":"No"
      },
      {
        nombre: "Bandeja portamigas",
        valor: portamigas?"Si":"No"
      },
      {
        nombre: "Luz interior",
        valor: luz?"Si":"No"
      },
      {
        nombre: "Timer",
        valor: stayOn?"Si (StayOn)":"No"
      },
      {
        nombre: "Tiempo Max.",
        valor: timerMins + " min."
      },
      {
        nombre: "Convección",
        valor: conveccion?"Si":"No"
      },
    ]
    return tabla;
  }

  tablaCalefaccion() {
    const {
      linea,
      anchoCm, altoCm, profundidadCm, 
      kcal, energia,
      espacioCalefaccionM2, 
      tiraje, salidaPosterior, salidaPosteriorLados, salidaLateral, salidaLateralLados, salidaConcentrica,
      encendido, valvula, quemador, camara, instalacion, eficiencia  
    } = this.state;
    let tabla = [
      {
        nombre: "Ancho",
        valor: anchoCm + " cm"
      },
      {
        nombre: "Alto",
        valor: altoCm + " cm"
      },
      {
        nombre: "Profundidad",
        valor: profundidadCm + " cm" + (linea === "Convector Móvil"?" (con soporte garrafa)":"")
      },
      {
        nombre: "Potencia",
        valor: kcal + " kcal/h"
      },
      {
        nombre: "Espacio a calefaccionar",
        valor: espacioCalefaccionM2 + " m2*"
      },
      {
        nombre: "Tipo de tiraje",
        valor: tiraje == 0?"Sin tiraje":tiraje==1?"TB":tiraje==2?'TBU':tiraje==3?"TB/TBU":"Tiro natural",
      },
      {
        nombre: "Tipo de energía",
        valor: energia
      },
      {
        nombre: "Salida posterior",
        valor: salidaPosterior?("Si" + (salidaPosteriorLados?" (Der. o Izq.)":"")):"No"
      },
      {
        nombre: "Salida lateral",
        valor: salidaLateral?("Si" + (salidaLateralLados?" (Der. o Izq.)":"")):"No"
      },
      {
        nombre: "Salida concéntrica",
        valor: salidaConcentrica?"Si":"No"
      },
      {
        nombre: "Encendido",
        valor: encendido
      },
      {
        nombre: "Válvula de seguridad",
        valor: valvula?"Si":"No"
      },
      {
        nombre: "Quemador",
        valor: quemador
      },
      {
        nombre: "Cámara",
        valor: camara
      },
      {
        nombre: "Instalación",
        valor: instalacion
      },
      eficiencia?
      {
        nombre: "Eficiencia energética",
        valor: eficiencia
      }:
      {
        nombre: "Piloto atmosférico",
        valor: "Si"
      },
    ]
    return tabla;
  }

  render() {
    const responsive = {
      todo: { breakpoint: { max: 10000, min: 0 }, items: 1 },
    };
    let categoria;
    let tabla;
    switch (this.props.params.categoria) {
      case "agua_caliente": categoria = "Agua caliente";
      tabla = this.tablaAguaCaliente();
      break;
      case "cocina": categoria = "Cocina";
      tabla = this.tablaCocina();
      break;
      default: categoria = "Calefacción";
      tabla = this.tablaCalefaccion();
    }
    const {modelo} = this.state;

    return (
      <React.Fragment>
        <div style={{width: "100%", height: "4vw"}}/>
        <div style={{width: "80%", marginLeft: "auto", marginRight: "auto"}}>
            <div className="detalle_links">
                <Link to="/home">Home</Link>
                {" / "}
                <Link to={`/productos/${this.props.params.categoria}`}>{categoria}</Link>
                {" / "}
                {this.props.params.linea && 
                <Link to={`/productos/${this.props.params.categoria}?linea=${this.props.params.linea}`}>{this.props.params.linea}</Link>
                }
                {" / "}
                {modelo}
            </div>
            <div style={{height: "3vw"}}/>
            <div style={{display: "flex", width: "100%", height: "fit-content", gap: 60, flexWrap: "wrap", justifyContent: "space-around", justifyItems: "center", alignContent: "stretch", alignItems: "stretch"}}>
                <div style={{flex: 60, minWidth: 250, maxWidth: "min(80%,680px)", width: "100%", minHeight: 100, zIndex: 0}}>
                  <Carousel
                    responsive={responsive}
                    showDots={true}
                    draggable={false}
                    infinite={this.state.imagenes.length > 1?true:false}
                    autoPlay={true}
                  >
                    {this.state.imagenes.map((i) => {
                        return (
                          <div
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%"
                            }}
                          >
                            <img
                              alt=""
                              src={i}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        );
                      })}
                  </Carousel>                  
                </div>
                <div style={{flex: 40, gap: 10, display: "flex", flexDirection: "column", justifyContent: "space-between", justifyItems: "space-between", minWidth: 250, maxWidth: 450, width: "100%"}}>
                  <h1 className="detalle_modelo">{this.state.modelo}</h1>
                  <h2 className="detalle_descripcion">{this.state.descripcion}</h2>
                  <h3 className="detalle_calidad">Características de calidad:</h3>
                  <ul className="detalle_calidad_texto">
                      {this.state.caracteristicas?.split("\n").map((c) => 
                      <li>{c}</li>)}
                  </ul>
                  <div className="detalle_sellos_container">
                    {
                      this.state.garantia && 
                      <img src={require("../../res/iconos/garantia_" + this.state.garantia + ".png")}
                        style={{
    
                          position: "relative",
                          objectFit: "cover",
                          objectPosition: "center",
                          height: 106,
                      }} />
                    }
                  
                    <img src={require("../../res/iconos/calidad.png")}
                      style={{
                        position: "relative",
                        objectFit: "cover",
                        objectPosition: "center",
                        height: 106,
                    }} />
                    {
                      this.state.eficiencia ? 
                      <img src={require("../../res/iconos/eficiencia_" + this.state.eficiencia + ".png")}
                        style={{
                          position: "relative",
                          objectFit: "cover",
                          objectPosition: "center",
                          height: 106,
                      }} /> : <div style={{width: 106, height: 106}}/>
                    }
                  </div>
                  <p className="detalle_legal"><span style={{color: "#9d1c21"}}>*</span>Leer <a href="/informacion_legal">información legal.</a></p>
                  <div className="detalle_botones_container">
                        {this.state.manualPath && 
                        <>
                        <button
                          onClick={() => this.descargarManual()}
                          className="detalle_boton"
                        >
                          Descargar manual
                        </button>
                        <a
                          id="botonManual"
                          href={this.state.manualUrl}
                          download={this.state.manualPath.split("/").slice(-1)}
                          style={{ display: "none" }}
                        />
                        </>
                        }
                        <Link to="/donde_comprar"><button className="detalle_boton">Dónde comprarlo</button></Link>
                  </div>
                  {this.state.discontinuado && 
                  <p className="detalle_discontinuado"><span style={{color: "#9d1c21"}}>{"*Producto discontinuado: "}</span>consultar stock en su comercio más cercano.</p>}
                </div>
            </div>
            <div style={{height: "6vw"}}/>
            <h1 className="detalle_seccion">Información técnica</h1>
            <div className="detalle_tabla">
            {tabla.map((c) => {
              return (
                <div className="detalle_campo">
                  <h4 className="detalle_campo_nombre">{c.nombre}</h4>
                  <p className="detalle_campo_valor">{c.valor}</p>
                </div>
              );
            })}
            </div>
            {this.state.categoria == "calefaccion" && 
            <p className="detalle_aclaracion">*Se considera una altura de techo de 2,70 mts del espacio a calefaccionar.</p>
            } 
            <div style={{height: "3vw"}}/>
            {
              this.state.relacionados?.length > 0 && 
              <>
              <h1 className="detalle_seccion">Productos relacionados</h1>
              <div style={{display: "flex", flexWrap: "wrap", width: "100%", gap: 24, justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                {this.state.relacionados.map((p,i) => 
                <Producto key={`card_producto_${this.state.id}_${i}`} producto={p} setUrl={() => {}}/>)}
              </div>
              </>
            }
        </div>
        
        <div style={{width: "100%", height: "7vw"}}/>
      </React.Fragment>
    );
  }
}

export default withRouterAndNavigate(Detalle);
