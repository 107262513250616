import React, { Component } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const BannersMobile = ({ banners }) => {
  const CustomDot = ({ onClick, ...rest }) => {
    const {
      onMove,
      index,
      active,
      carouselState: { currentSlide, deviceType }
    } = rest;
    return (
      <button
        className={active ? "home_carousel_dot_activo" : "home_carousel_dot"}
        onClick={() => onClick()}
      />
    );
  };
  const responsive = {
    todo: { breakpoint: { max: 10000, min: 0 }, items: 1 },
  };
  return (
    <React.Fragment>
      <div
        style={{
          position: "relative",
          width: "100%",
          zIndex: 0,
        }}
      >
        <div style={{ overflow: "hidden" }}>
          <Carousel
            responsive={responsive}
            showDots={true}
            draggable={false}
            infinite={true}
            removeArrowOnDeviceType={["todo"]}
            autoPlay={true}
            customDot={<CustomDot />}        
          >
            {banners.primero
              .filter((e) => e.plataforma == 1 || e.plataforma == 2)
              .map((e, i) => {
                return (
                  <Link to={e.link}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        minHeight: 200,
                        maxHeight: 800,
                      }}
                    >
                      <img
                        alt={"banner1-" + i}
                        src={e.url}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </Link>
                );
              })}
          </Carousel>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BannersMobile;
