import React, { Component } from "react";
import CalculoAgua from "./calculoAgua.jsx";

class Calculo extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const mobileBanner =
      window.innerWidth < 650;
    return (
      <React.Fragment>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: 250,
          }}
        >
          <img
            src={require("../../res/banners/agua_" + (mobileBanner ? "mobile":"desktop" ) + ".jpg")}
            style={{
              position: "relative",
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
              height: 250,
              zIndex: -1,
            }}
          />
        </div>

        <div style={{ height: "3vw", width: "100%" }} />
        <div className="calculo_titulo">Encontrá el termotanque que se adapte a tus necesidades</div>
        <div style={{ height: "1.5vw", width: "100%" }} />

        <CalculoAgua />

        <div style={{ height: "7vw", width: "100%" }} />
      </React.Fragment>
    );
  }
}

export default Calculo;
