import React, { Component } from "react";
import calcularPx from "../general/calcularPx.js";
import Select from "react-dropdown-select";
import Producto from "../productos/producto.jsx";

class CalculoAgua extends Component {
  state = {
    gas: false,
    personas: -1,
    resultado: null,
  };

  customContentRenderer({ props }) {
    return (
      <React.Fragment>
        <div
          className="caclulo_select"
          style={{ width: "100%", height: "100%"}}
        >
          <input
            type="text"
            id={props.placeholder}
            readOnly={true}
            placeholder={"Seleccionar"}
            style={{
              border: "none",
              borderColor: "transparent",
              outline: "none",
              caretColor: "transparent",
              width: "100%",
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  calcular() {
    const { gas, personas } = this.state;

    fetch("/api/productos/?linea=agua_caliente")
    .then((res) => res.json())
    .then((productos) => {
      const productosFiltrados = productos.filter((p) => {
        let devolver = true;
        if (p.personasHasta != personas) {
          devolver = false;
        }
        if ((gas && p.energia == 'e') || (!gas && p.energia == 'g')) {
          devolver = false;
        }
        return devolver;
      }).sort((a,b) => a.capacidadLt - b.capacidadLt).slice(0, 4);
      this.setState({ resultado: productosFiltrados });
    });
  }

  getTipo(tipos) {
    let retorno = "";
    let nombres = {
      g: "a gas",
      e: "eléctrico",
    };
    Object.keys(tipos).forEach((t) => {
      if (tipos[t]) {
        retorno += " o " + nombres[t];
      }
    });
    return retorno.slice(3);
  }

  render() {
    const resultado = this.state.resultado;
    return (
      <React.Fragment>
        <div
          className="calculo_recuadro"
        >
          <div style={{flex: 1}}>
            <h1
              className="calculo_campo_titulo"
            >
              ¿Tenés conexión a gas?
            </h1>
            <div
              className="calculo_radio"
              onChange={(event) =>
                this.setState({ gas: event.target.value, resultado: null })
              }
            >
              <input type="radio" value={true} name="gas" />
              Si
              <input
                type="radio"
                value={false}
                defaultChecked
                name="gas"
                style={{ marginLeft: calcularPx(44) }}
              />
              No
            </div>
          </div>
          
          <div style={{flex: 1}}>
            <h1
              className="calculo_campo_titulo"
            >
              ¿Cantidad de personas?
            </h1>
            <Select
              className="calculo_select"
              style={{
                position: "relative",
                border: "1px solid #d9d9d9",
                borderRadius: 5,
                width: "100%",
                height: 28,
              }}
              searchable={false}
              placeholder="termico"
              dropdownGap={0}
              color="#9d1c21"
              options={[
                { label: "1 a 2", value: 2 },
                { label: "2 a 3", value: 3 },
                { label: "3 a +4", value: 4 },
              ]}
              onChange={(values) => {
                document.getElementById("termico").value = values[0].label;
                this.setState({
                  personas: values[0].value,
                  resultado: null,
                });
              }}
              contentRenderer={this.customContentRenderer}
            />
          </div>
          

          <button
            className="calculo_boton"
            style={{flex: 1}}
            disabled={
              this.state.personas == -1
            }
            onClick={() => this.calcular()}
          >
            Ver resultado
          </button>

          <div className="calculo_aclaracion" style={{width: "100%"}}>*Los resultados son meramente orientativos</div>
        </div>
        {
          resultado && 
          <>
          <div style={{height: "2vw"}}/>
          <h1 className="calculo_resultado">Resultado</h1>
          <div style={{display: "flex", flexWrap: "wrap", width: "80%", marginLeft: "auto", marginRight: "auto", gap: 24, justifyContent: "center", alignContent: "center", alignItems: "center"}}>
            {resultado?.map((p,i) => 
            <Producto key={`card_producto_${this.state.id}_${i}`} producto={p} setUrl={() => {}}/>)}
          </div>
          </>
        }
      </React.Fragment>
    );
  }
}

export default CalculoAgua;
