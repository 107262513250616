import React, { Component } from "react";

class Legal extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div style={{width: "80%", marginLeft: "auto", marginRight: "auto"}}>
        <div style={{ width: "100%", height: "7vw" }} />
        <div className="legal_titulo">Copyright ©  2024 Coppens S.A.</div>
        <div className="legal_texto">Todos los derechos reservados. Queda expresamente prohibida la reproducción total o parcial de cualquier contenido o dato presente en este sitio web.</div>
        <div className="legal_separador"/>
        <div className="legal_titulo">Aviso legal</div>
        <div className="legal_texto">Las imágenes presentadas en nuestro sitio web son meramente ilustrativas. Coppens S.A., en su compromiso por mantener una política de mejora continua, se reserva el derecho de realizar modificaciones en sus productos sin previo aviso.</div>
        <div className="legal_espacio_parrafo" />
        <div className="legal_texto">A través de la sección "Dónde Comprar", Coppens S.A. proporciona a los usuarios acceso a diversos contenidos con fines orientativos. Los textos, fotografías, gráficos, imágenes, mapas, íconos, tecnologías, software, enlaces y otros contenidos audiovisuales o sonoros, así como su diseño gráfico, pueden contener inexactitudes que son independientes de Coppens S.A. Es importante destacar que las ubicaciones de los comercios en los mapas son referenciales.</div>
        <div className="legal_espacio_parrafo" />
        <div className="legal_texto">Cualquier empresa o comercio mencionado en esta sección puede solicitar su exclusión del sistema contactándose a través de <a href="mailto:administracionventas@coppens.com.ar">administracionventas@coppens.com.ar</a>. Los comercios detallados en esta sección no son propiedad ni tienen vinculación accionaria con Coppens S.A. La empresa no asume responsabilidad por la eventual falta de stock en los comercios indicados, ni por la posibilidad de que dichos establecimientos dejen de comercializar temporal o permanentemente los productos de Coppens. Se recomienda ponerse en contacto con el comercio antes de visitarlo.</div>
        <div className="legal_separador"/>
        <div className="legal_titulo">Garantía</div>
        <div className="legal_texto">Coppens S.A garantiza al comprador el normal funcionamiento por el termino de <b>7 (siete) años</b> en el caso de los termotanques, y <b>1 (un) año</b> para los convectores a partir de la fecha de adquisición. Para esto, se deberán cumplir estrictamente las indicaciones del Manual de Instalación, Uso y Mantenimiento que acompaña cada unidad.</div>
        <div className="legal_espacio_parrafo" />
        <div className="legal_texto">La empresa se compromete a su reparación cuando las fallas se originen en condiciones normales de uso, o se presente cualquier <b>defecto de fabricación</b> y/o vicio del material dentro de la República Argentina.</div>
        <div className="legal_espacio_parrafo" />
        <div className="legal_texto">Aquellos componentes de terceros  tales como: termostato, termocupla, quemador, resistencia eléctrica, piloto analizador, grifo de purga y válvula de seguridad gozarán de la garantía que otorgan sus respectivos fabricantes, que a la fecha es de <b>1 (un) año</b>, plazo dentro del cual se procederá al reemplazo de la pieza defectuosa.</div>
        <div className="legal_espacio_parrafo" />
        <div className="legal_texto">Para más información, le recomendamos leer el manual que se encuentra en el equipo.</div>      
        <div style={{width: "100%", height: "7vw"}}/>
      </div>
    );
  }
}

export default Legal;
