import React, { Component } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const BannersDesktop = ({ banners }) => {
  const CustomDot = ({ onClick, ...rest }) => {
    const {
      onMove,
      index,
      active,
      carouselState: { currentSlide, deviceType }
    } = rest;
    return (
      <button
        className={active ? "home_carousel_dot_activo" : "home_carousel_dot"}
        onClick={() => onClick()}
      />
    );
  };
  const responsiveBanner = {
    todo: { breakpoint: { max: 10000, min: 0 }, items: 1 },
  };
  return (
    <React.Fragment>
      <div
        style={{
          position: "relative",
          zIndex: 0,
        }}
      >
        <Carousel
          responsive={responsiveBanner}
          showDots={true}
          draggable={false}
          infinite={true}
          autoPlay={true}
          partialVisible={false}
          customDot={<CustomDot />}        
        >
          {banners.primero
            .filter((e) => e.plataforma == 0 || e.plataforma == 2)
            .map((e, i) => {
              return (
                <Link to={e.link}>
                  <div
                    style={{
                      maxHeight: "800px",
                    }}
                  >
                    <img
                      alt={"banner1-" + i}
                      src={e.url}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover"
                      }}
                    />
                  </div>
                </Link>
              );
            })}
        </Carousel>
      </div>
    </React.Fragment>
  );
};

export default BannersDesktop;
